<template>
    <div id="factor">
        <!-- 查询框 -->
        <div class="queryInput">

            <el-input placeholder="请查询" v-model="queryinput" class="input-with-select" @keyup.enter.native="ItemQuery()">
                <el-button slot="append" icon="el-icon-search" @click="ItemQuery()"></el-button>
            </el-input>
        </div>
        <!-- 第一级 -->
        <div v-if="!max" class="f_level">
            <div class="item" v-for="(item, index) in data" :key="index" @click="handleChoose({ f_level: item.c_name })">
                <div class="icon">
                    <component :is="components[item.e_name]" />
                </div>
                <div class="c_name">{{ item.c_name }}</div>
                <div class="e_name">{{ item.e_name }}</div>
                <div class="c_length">({{ item.data.length }})</div>
            </div>
        </div>
        <!-- 第二级 -->
        <div v-if="max == 1" class="s_level">
            <div class="item" v-for="(item, index) in data" :key="index">
                <div class="title">
                    <div class="name">
                        {{ item.c_name }}
                        {{ item.e_name }}
                    </div>
                    <div class="c_length">({{ item.data.length }})</div>
                </div>
                <div class="children">
                    <div class="c_item_wrapper" v-for="(c_item, c_index) in item.data" :key="c_index">
                        <div class="icon"></div>
                        <div class="c_item">
                            <div class="c_item_name" @click="
                                handleChoose(
                                    { f_level, s_level: item.c_name, t_level: c_item.c_name },
                                    c_item.e_name
                                )
                            ">
                                {{ c_item.c_name }}
                                {{ c_item.e_name }}
                            </div>
                            <div class="c_item_c_length">({{ c_item.data.length }})</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第三、四级 -->
        <div v-if="max == 3" class="t_level">
            <div class="left">
                <div class="title">
                    <div class="name">
                        {{ currentData.c_name }}
                        {{ currentData.e_name }}
                    </div>
                    <div class="length">({{ data.length }})</div>
                </div>
                <div class="children">
                    <div class="item" v-for="(item, index) in data" :key="index">
                        <div class="icon"></div>
                        <div class="c_item">
                            <div @click="handleClick(item, index)" :class="[
                                'c_item_name',
                                { active: index === currentChildIndex },
                            ]">
                                {{ item.c_name }}
                                {{ item.e_name }}
                            </div>
                            <!-- <div class="c_item_c_length">
                                ({{ data.length }})
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="title">
                    <div class="name">
                        {{ currentChild.c_name }}
                        {{ currentChild.e_name }}
                    </div>
                    <div class="addon"></div>
                </div>
                <div class="wrapper">
                    <div class="item" v-for="(item, index) in items" :key="index">
                        <div :class="['top', { collapsed: item.collapsed }]" @click="handleCollapse(index, item.collapsed)">
                            <div :class="['top-content', { collapsed: item.collapsed }]">
                                <div class="icon"></div>
                                <!-- <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
                                    <div class="icon"></div>
                                </el-tooltip> -->
                                <div class="name">
                                    {{ item.label }}
                                    <template v-if="
                                        !index && (currentChild.c_unit || currentChild.e_unit)
                                    ">
                                        ({{ currentChild.c_unit + "" + currentChild.e_unit }})
                                    </template>
                                </div>
                                <div class="action"></div>
                            </div>
                        </div>
                        <div :class="['content-wrapper', { collapsed: item.collapsed }]">
                            <div :class="['content', { collapsed: item.collapsed }]">
                                {{ currentChild[item.field] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import factors from "../../../utils/factors.json";
import { industry, energy, household, waste, transport, tree } from "../../../core/icon";
const components = {
    'Industrial Products': 'industry',
    'Energy Products': 'energy',
    'Household Products': 'household',
    'Waste treatment': 'waste',
    'Transportation server': 'transport',
    'Carbon dioxide': 'tree'
};
export default {
    name: "Factor",
    components: {
        industry, energy, household, waste, transport, tree
    },
    data() {
        return {
            queryinput: "",
            data: [],
            max: 0,
            currentData: {
                e_name: "",
                c_name: "",
            },
            currentChild: {},
            currentChildIndex: 0,
            items: [
                {
                    label: "上游排放（Upstream emissions）",
                    field: "upstream_emissions",
                    collapsed: true,
                },
                {
                    label: "下游排放（Downstream emissions）",
                    field: "downstream_emissions",
                    collapsed: true,
                },
                {
                    label: "排放环节（Emission processes）",
                    field: "emission_processes",
                    collapsed: true,
                },
                {
                    label: "排放温室气体占比（GHG percentage）",
                    field: "ghg_percentage",
                    collapsed: true,
                },
                { label: "数据时间（Year）", field: "year", collapsed: true },
                {
                    label: "不确定性（Uncertainty）",
                    field: "uncertainty",
                    collapsed: true,
                },
                { label: "备注（Notes）", field: "notes", collapsed: true },

                {
                    label: "数据质量（Data quality）",
                    field: "data_quality",
                    collapsed: true,
                },
                {
                    label: "参考文献/数据来源（Data source）",
                    field: "data_source",
                    collapsed: true,
                },
                { label: "修改人", field: "principal", collapsed: true },
                { label: "最新修改时间", field: "updatertime", collapsed: true },
            ],
            components,
        };
    },
    activated() {
        // console.log("activated, tool", factors, this.$route);
        this.generateData();
    },
    computed: {
        // route: function(){
        //     return this.$route;
        // },
        // routePath: function(){
        //     return this.$route.path;
        // },
        routeQuery: function () {
            return this.$route.query;
        },
        f_level: function () {
            return this.$route.query.f_level;
        },
        s_level: function () {
            return this.$route.query.s_level;
        },
        t_level: function () {
            return this.$route.query.t_level;
        },
        fo_level: function () {
            return this.$route.query.fo_level;
        },
    },
    watch: {
        // route: (val) => {
        //     console.log('route', val);
        // },
        // routePath: (val) => {
        //     console.log('routePath', routePath);
        // },
        routeQuery: function (val) {
            // console.log("routeQuery", val, this);
            this.generateData();
        },
    },
    methods: {
        ItemQuery() {
            //  console.log(this.input);
            const text = this.$router.resolve({
                name: "search",
                query: {
                    id: this.queryinput,
                },
            });
            window.open(text.href, "_blank");
            this.queryinput = "";
        },
        generateData() {
            this.max =
                (this.f_level ? 1 : 0) +
                (this.s_level ? 1 : 0) +
                (this.t_level ? 1 : 0) +
                (this.fo_level ? 1 : 0);
            let strs = [this.f_level, this.s_level, this.t_level, this.fo_level];
            let getChildren = (level, children) => {
                if (!(children instanceof Array) || !children.length) return [];
                if (!strs[level - 1]) {
                    return children;
                }
                for (let i = 0; i < children.length; i++) {
                    if (strs[level - 1] == children[i].c_name) {
                        // temp.push(children[i])
                        let temp = getChildren(level + 1, children[i].data);
                        if (level == 3) {
                            this.currentData.e_name = children[i].e_name;
                            this.currentData.c_name = children[i].c_name;
                            console.log("level", children[i].e_name, this.currentData);
                            this.handleClick(temp[0], 0);
                        }
                        return temp;
                    }
                }
            };
            let temp = getChildren(1, factors);
            // console.log("temp", temp);
            this.data = temp;
        },
        handleClick(row, index) {
            // console.log("handleClick", row);
            this.currentChild = {
                ...row,
            };
            this.currentChildIndex = index;
            // this.$forceUpdate();
        },
        handleCollapse(index, collapsed) {
            let temp = {
                ...this.items[index],
                collapsed: !collapsed,
            };
            this.items[index] = temp;
            // console.log("handleCollapse", index, collapsed, this.items[index]);
            this.$set(this.items, index, temp);
        },
        handleChoose(query, e_name) {
            this.$router.push({
                path: "/tool/factor",
                query,
            });
            // if (e_name){
            //     this.currentData.e_name = e_name;
            //     this.currentData.c_name = query.t_level;
            // }
        },
    },
};
</script>
<style lang="less">
@import "./index.less";
</style>
